import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@page2flip/core';
import { GoogleAnalyticsEvent } from '@page2flip/core/common';
import { spoVersion } from "../../../config/window-config";

declare let ga: Function;
declare let gtag: Function;

/**
 * Service to send Google Analytics events.
 */
@Injectable()
export class GoogleAnalytics{

  // This is used to send the initial page view after consent is configured
  private _pagesOpened: string[] = [];

  private _documentId: string;

  private initialPagesSent = false;

  constructor(@Inject(WINDOW) private window: any) {
  }

  createGtag(cookieConsent, measurementId: string) {
    if (!spoVersion) {// With the latest changes this is done directly in the index
      gtag('config', measurementId);
    }
    if (cookieConsent) {
      gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });
      this.sendInitialPageView('gtag');
    }
  }

  createGa(cookieConsent, measurementId: string) {
    if (typeof cookieConsent === 'boolean') {
      ga('create', measurementId
        , cookieConsent ? 'auto' : {
        storage: 'none'
      }, 'clientSideTracker');
      this.sendInitialPageView('ga');
    }
  }

  sendGtagEvent(event: GoogleAnalyticsEvent, documentId: string) {
    const metaTag = document.querySelector('meta[name="content-pretty-url"]');
    const prettyUrl = metaTag ? metaTag.getAttribute('content') : null;

    if (!this.initialPagesSent && event.category === 'page' && event.action === 'view') {
      this._pagesOpened.push(event.custom1);
      this._documentId = documentId;
    }
    const eventPayload: { [key: string]: any } = {
      action: event.action,
      documentId: documentId,
      dimension1: event.custom1,
      dimension2: event.custom2,
      dimension3: event.custom3
    };
    if (prettyUrl) {
      eventPayload.page_path = prettyUrl;
    }
    gtag('event', event.category, eventPayload);
  }

  sendGaEvent(event: GoogleAnalyticsEvent, documentId: string) {
    if (!this.initialPagesSent && event.category === 'page' && event.action === 'view') {
      this._pagesOpened.push(event.custom1);
      this._documentId = documentId;
    }
    ga('clientSideTracker.send', 'event',
      event.category,
      event.action,
      documentId,
      undefined,
      {
        dimension1: event.custom1,
        dimension2: event.custom2,
        dimension3: event.custom3
      });
  }

  private sendInitialPageView(type: 'gtag' | 'ga') {
    this.initialPagesSent = true;
    this._pagesOpened.map<GoogleAnalyticsEvent>(page => {
      return { category: "page", action: "view", custom1: page };
    }).forEach(pageEvent => {
      if (type === 'gtag') {
        this.sendGtagEvent(pageEvent, this._documentId);
      } else {
        this.sendGaEvent(pageEvent, this._documentId);
      }
    })
  }

}
